
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app'
import { getAnalytics, setUserProperties, setUserId } from "firebase/analytics";
import { getMessaging, deleteToken } from 'firebase/messaging'
import { getAuth, onAuthStateChanged, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import env from '@/api/env';
import { getAppVersion, getWebVersion } from "@/utils/version";

import _ from 'lodash';

const firebaseConfig = env.firebaseConfig();

export default {


	instance() {

		if(!this.app)
		{
			this.app = initializeApp(firebaseConfig)
			this.messaging = getMessaging(this.app)
			this.analytics = getAnalytics(this.app)
			
			if (Capacitor.isNativePlatform()) {
				this.auth = initializeAuth(this.app, {
					persistence: indexedDBLocalPersistence
				})
			} else {
				this.auth = getAuth()
			}

			onAuthStateChanged(this.auth, ()=>{
				const { currentUser } = this.auth;
				this.currentUser = currentUser;
			})
			
		}

		return { messaging: this.messaging, auth: this.auth };
		

	},

	async setAnalytics(user, communityName, deviceInfo, dark, theme)
	{

		const userId = user._id;
		const role = user.roles[0];
		const name = user.fullName;
		const communityId = user.community.communityId;
		const version = await getAppVersion();
		const webVersion = await getWebVersion();
		const { platform } = deviceInfo;

		const visitor = { id: userId, role, name, platform, dark, theme };
		const account = { id: communityId, communityName, env: env.name() };

		const fields = {};

		if(version)
		{
			visitor.version = version;
		}

		if(webVersion)
		{
			visitor.webVersion = webVersion;
		}

		_.each(visitor, (value, key) => {
			fields[`visitor_${key}`] = value;
		})
		
		_.each(account, (value, key) => {
			fields[`account_${key}`] = value;
		})

		console.log(fields);

		setUserProperties(this.analytics, fields)
		setUserId(this.analytics, userId);

		
	},

	async deleteFirebaseToken() {
		try {
			await deleteToken(this.messaging);
		} catch(e) {
			console.warn('No Firebase Token present')
		}
	},

	async exists()
	{

		return new Promise((resolve)=>{
			const dbRequest = window.indexedDB.open("firebaseLocalStorageDb");
			dbRequest.onsuccess = () => {

				try {
					const db = dbRequest.result;
					const tx = db.transaction(["firebaseLocalStorage"], 'readonly');
					const store = tx.objectStore("firebaseLocalStorage");
					const countRequest = store.count();

					countRequest.onsuccess = () => {
						resolve(countRequest.result > 0);
					}

					countRequest.onerror = () => {
						resolve(false);
					}
					
				} catch(e) {
					resolve(false);
				}
				
			}

			dbRequest.onerror = () => {
				resolve(false);
			}
		});
  
	}


}