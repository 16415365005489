import _ from 'lodash';
import { set } from "@/utils/vuex";
import Vuetify from '@/plugins/vuetify';
import store from '../index';
import Vue from 'vue';

const state = () => ({
	route: null,
	visible: false,
	loading: {},
	excluded: ['login'],
	mobileHome: '/library/new',
	desktopHome: '/library/new',
	phoneViewport: null,
	online: navigator.onLine,
	slowConnection: false,

	searchBarRoutes: [ '/events/all', '/directory/residents', '/directory/staff', '/directory/friends', '/library/category' ],

	//flagged routes. merge into others if they evaluate
	flagged: {
		'categories': [
			{ name: 'Requests', value: 'Request', icon: 'mdi-format-list-checks', flag: 'requestExperienceEnabled', flagValue: false}
		],
		'mobile[4].items': [ //more
			{ name: 'Share K4Community Plus', icon: 'mdi-account-group', to: '/share', roles: ['Resident', 'Staff'], after: 'Home Controls' },
			{ name: 'Requests', icon: 'mdi-format-list-checks', to: '/library/category/Requests', flag: 'requestExperienceEnabled', flagValue: true, after: 'Directory'},
		],
		'desktop': [
			{ name: 'Share', icon: 'mdi-account-group', to: '/share', roles: ['Resident', 'Staff'], after: 'Home Controls' },
			{ name: 'Requests', icon: 'mdi-format-list-checks', to: '/library/category/Requests/all', flag: 'requestExperienceEnabled', flagValue: true, after: 'Directory' },
			{ name: 'Shortcuts', icon: 'mdi-link', to: '/library/shortcuts', flag: 'shortcutsEnabledPlus', flagValue: true, after: 'What\'s New' },
			
		],
		'tabs.library': [
			{ name: 'Shortcuts', to: '/library/shortcuts', flag: 'shortcutsEnabledPlus', flagValue: true, after: 'What\'s New' },
		],
		'homeOptions': [
			{ name: 'Shortcuts', icon: 'mdi-link', to: '/library/shortcuts', flag: 'shortcutsEnabledPlus', flagValue: true, after: 'What\'s New' },
		],
		'tabs.events': [
			
			{ name: 'My Events', icon: 'mdi-home', to: '/events/signups', roles: ['Resident','Staff'], after: 'All'},
			{ name: 'Invites', icon: 'mdi-home', to: '/events/invites', roles: ['Resident','Staff'], after: 'All'}
		]
	},
	//items to show in sidebar profile menu
	profileMenu: [
		{ name: 'Profile', to: '/profile' },
		{ name: 'Logout', to: '/logout', not: 'navigation/isTablet' }
	],

	//categories to show in library
	categories: [
		{ name: 'Notices', value: 'Notice', icon: 'mdi-bulletin-board'},
		{ name: 'Photos', value: 'Photo', icon: 'mdi-image'},
		{ name: 'Resources', value: 'Resource', icon: 'mdi-library-shelves'},
		{ name: 'Videos', value: 'Video', icon: 'mdi-video-vintage'},
	],

	//categories to show in home controls
	controls: [
		{ name: 'Lights', group: 'lights'},
		{ name: 'Fans', group: 'fans'},
		{ name: 'Thermostats', group: 'thermostats'},
		{ name: 'Doors', group: 'doors'},
		{ name: 'Windows', group: 'windows'},
	],

	// Selector options for home screen
	homeOptions: [
		{ name: 'What\'s New', icon: 'mdi-home', to: '/library/new' },
		{ name: 'Library', icon: 'mdi-library', to: '/library/category' },
	],

	//top navigation tabs
	tabs: {
		'library': [
			{ name: 'What\'s New', to: '/library/new' },
			{ name: 'Library', to: '/library/category'}
		],
		'events': [
			{ name: 'All', icon: 'mdi-home', to: '/events/all' },
		],
		'directory': [
			{ name: 'Residents', to: '/directory/residents' },
			{ name: 'Staff', to: '/directory/staff' },
			{ name: 'Friends', to: '/directory/friends' }
		],
		'dining': [
			{ name: 'Menus', to: '/dining/restaurant', },
			{ name: 'Dining Info', to: '/dining/info/Menu' }
		],
		'controls': [
			{ name: 'Devices', to: '/controls/list' },
			{ name: 'Scenes', to: '/controls/scenes' },
		]
	},

	//menus for mobile
	mobile: [
		{ name: 'Home', view: 'Home', icon: 'mdi-home', to: '/'},
		{ name: 'Search', view: 'Search', icon: 'mdi-magnify', to: '/search'},
		{ name: 'Events', view: 'Events', icon: 'mdi-calendar', to: '/events'},
		{ name: 'Dining', view: 'Dining', icon: 'mdi-silverware-fork-knife', to: '/dining'},
		{ name: 'More', view: 'More', icon: 'mdi-dots-horizontal', to:'/more', items: [
			{ name: 'Directory', icon: 'mdi-card-account-details', to: '/directory'},
			{ name: 'Home Controls', icon: 'mdi-lightbulb-on', to: '/controls/list', if: 'user/system' },
		]},
	],
	//menus for desktop
	desktop: [
		{ name: 'What\'s New', icon: 'mdi-home', to: '/library/new' },
		{ name: 'Library', icon: 'mdi-library', items: [
			{ menuComponent: 'CategoryList' },
		]},
		{ name: 'Events', icon: 'mdi-calendar', to: '/events/all' },
		{ name: 'Dining', icon: 'mdi-silverware-fork-knife', items: [
			{ menuComponent: 'RestaurantList', to: '/dining/restaurant' },
			{ name: 'Dining Info', to: '/dining/info/Menu/all' }
		]},
		{ name: 'Directory', icon: 'mdi-card-account-details', to: '/directory'},
		{
			name: 'Home Controls', icon: 'mdi-lightbulb-on', to: '/controls/list', if: 'user/system', items: [
				{ name: 'Devices', to: '/controls/list' },
				{ name: 'Scenes', to: '/controls/scenes' },
			]
		},
		{ name: 'Chat', icon: 'mdi-chat', to: '/chat/channels', component: 'chat/ChatList', if: 'user/notStaff' },
	],

	//routes for both mobile and desktop
	routes: [
		{ name: 'Library', path: '/library', view: 'Library', items: [
			{ name: 'What\'s New', path: '/library/new', component: 'feed/FeedNew' },
			{ name: 'Library', path: '/library/category', component: 'feed/CategoryList'},
			{ name: 'Library', path: '/library/category/:category/:tag', component: 'feed/CategoryDetails', paramTitle: 'tag'},
			{ name: 'Folders', path: '/library/category/:category', component: 'feed/TagList', paramTitle: 'category'},
			{ name: 'Library', path: '/library/item/:id/:type', component: 'feed/ItemView'},
			{ name: 'Library', path: '/library/details/:id', component: 'feed/FeedDetails'},
			{ name: 'Shortcuts', path: '/library/shortcuts', component: 'shortcuts/Shortcuts' },
		]},
		{ name: 'Chat', path: '/chat', view: 'Chat', items: [
			{ name: 'Chat', path: '/chat/channels', component: 'chat/ChatList' },
			{ name: 'New Chat', path: '/chat/users', component: 'chat/ChatUsers' },
			{ name: 'Chat', path: '/chat/details/:id', component: 'chat/ChatMessages', getterTitle: 'chat/channelTitle'}
		]},
		{ name: 'Home Controls', view: 'Controls', path: '/controls', items: [
			{ name: 'Home Controls', path: '/controls/:type/:id', component: 'controls/DeviceDetails'},
			{ name: 'Home Controls', path: '/controls/list', component: 'controls/DeviceList'},
			{ name: 'Home Controls', path: '/controls/scenes', component: 'controls/scenes/SceneList'},
		]},
		{ name: 'Dining', view: 'Dining', path: '/dining', items: [
			{ name: 'Menu', path: '/dining/restaurant/:restaurantId/:menuId', component: 'dining/MenuDetails', getterTitle: 'dining/menuTitle'},
			{ name: 'Menus', path: '/dining/restaurant/', component: 'dining/RestaurantList'},
			{ name: 'Menus', path: '/dining/restaurant/:restaurantId', component: 'dining/MenuList', getterTitle: 'dining/restaurantTitle'},
			{ name: 'Dining Info', path: '/dining/info/:category/:tag', component: 'feed/CategoryDetails', paramTitle: 'tag'},
			{ name: 'Dining Info', path: '/dining/info/:category', component: 'feed/TagList' }
		]},
		{ name: 'Directory', path: '/directory', view: 'Directory', items: [
			{ name: 'Residents', path: '/directory/:role', component: 'directory/DirectoryList', paramTitle: 'role' },
			{ name: 'Directory', path: '/directory/details/:id', component: 'directory/DirectoryDetails'}
		]},
		{ name: 'Events', path: '/events', view: 'Events', items: [
			{ name: 'Events', path: '/events/all', component: 'events/EventList'},
			{ name: 'Events', path: '/events/invites', component: 'events/EventInvites'},
			{ name: 'Events', path: '/events/signups', component: 'events/EventSignups'},
			{ name: 'Events', path: '/events/details/:id', component: 'events/EventDetails'}
		]},
		{ name: 'Search', path: '/search', view: 'Search'},
		{ name: 'Share', path: '/share', view: 'Share'},
		{ name: 'Profile', path: '/profile', view: 'Profile'},
		{ name: 'Calls', path: '/calls', view: 'Calls'},
		{ name: 'More', path: '/more', view: 'More'},
		{ name: 'Logout', path: '/logout', view: 'Logout' }
	]
});


const getters = {

	home(state, getters)
	{
		const home = getters.isCompact ? state.mobileHome : state.desktopHome;
		if (!state.homeOptions.some(o => home.indexOf(o.to) > -1)) {
			return state.homeOptions[0].to;
		}
		return home;
	},

	isMobile(state, getters, rootState, rootGetters)
	{
		const info = rootGetters['mobile/info'];
		return info.platform != 'web';
	},

	isPhone(state, getters)
	{
		return getters.isMobile && getters.isCompact;
	},

	isTablet(state, getters)
	{
		return getters.isMobile && !getters.isCompact;
	},

	isCompact() {
		return Vuetify.framework.breakpoint.xs;
	},
	isLoading(state)
	{
		return Object.values(state.loading).some(_.identity);
	},
	isCollapsed()
	{
		return Vuetify.framework.breakpoint.smAndDown;
	},
	showWeb(state, getters)
	{
		return !getters.isCompact;
	},
	showTabs(state, getters)
	{
		return getters.isCompact;
	},

	modalWidth(state, getters)
	{
		return (getters.isCompact) ? 300 : 450;
	},

	title(state, getters, rootState, rootGetters)
	{
		const { title, paramTitle, getterTitle } = state.route.meta;

		if(paramTitle)
		{
			let text = state.route.params[paramTitle];
			const category = state.route.params['category'];
			
			//special cases
			if (text === 'all' && paramTitle === 'tag' && category === 'Menu')
			{
				//dont show 'All Menus' for dining info
				text = `All Dining Info`;
			} else if (text === 'all' && paramTitle === 'tag' && category)
			{
				//all tags should and categories should return plural category
				text = `All ${category.at(-1) == 's'  ? category : category + 's' }`;
			} else if (paramTitle === 'category')
			{
				//all tags should and categories should return plural category
				text = category.at(-1) == "s" ? category : category + "s";
			} 

			return _.startCase(text);

		} else if(getterTitle) {
			return rootGetters[getterTitle];
		}

		return title;
	},

	routes(state)
	{
		return state.routes;

	},
	showBack(state, getters)
	{
		//show top back button on chat, and when top tabs are hidden (dining, library)
		return state.route.path.indexOf('/chat') === 0 
		|| state.route.path.indexOf('/search') === 0 
		|| !getters.showTopTabs;
	},

	showSearch(state)
	{
		return state.route.path.indexOf('/dining') === -1
	},

	hideTabBar(state)
	{
		//hide bottom tab bar on chat
		return state.route.path.indexOf('/chat/messages') === 0;
	},

	getFirstTab(state, getters)
	{
		return getters.tabs[0];
	},

	tabs(state)
	{
		const base = state.route.path.split('/')[1];
		return state.tabs[base];
	},

	isDetails(state)
	{
		return state.route.path.indexOf('details') > -1;
	},

	isProfile(state)
	{
		return state.route.path.indexOf('profile') > -1;
	},

	isResults(state)
	{
		return state.route.path.indexOf('results') > -1;
	},

	isSignups(state)
	{
		return state.route.path.indexOf('signups') > -1;
	},

	isInvites(state)
	{
		return state.route.path.indexOf('invites') > -1;
	},

	showTopTabs(state, getters)
	{
		//dont show top tabs (library,dining) if we are in nested page - minus special case for dining info
		return getters.isCompact && (
			_.keys(state.route.params).length === 0 
			|| state.route.path === '/dining/info/Menu'
		) && state.route.path !== '/profile'
	},

	isDirectoryDetails(state)
	{
		return state.route.path.indexOf('/directory/details') > -1;
	},

	isOffline(state)
	{
		return !state.online;
	},

	slowConnection(state)
	{
		return state.slowConnection;
	},
};

const mutations = {
	
	setVisible: set('visible'),
	setMobile: set('mobile'),
	setRoute: set('route'),
	setMobileHome: set('mobileHome'),
	setDesktopHome: set('desktopHome'),
	setPhoneViewport: set('phoneViewport'),
	setOnline: set('online'),
	setSlowConnection: set('slowConnection'),

	setLoading(state, { key, value }) {
		if (value) {
			Vue.set(state.loading, key, value);
		} else {
			Vue.delete(state.loading, key);
		}
	}
};

const actions = {

	init({state, commit, dispatch, rootGetters})
	{

		// watch for online/offline
		window.addEventListener("online", () => dispatch('setOnline', true));
		window.addEventListener("offline", () => dispatch('setOnline', false));
		
		//watch for flag updates
		const flaggedRoutes = state.flagged;
		const hasRoles = rootGetters['user/hasRoles'];
		store.watch(() => rootGetters['flags/flags'], (flags) => {

			_.forIn(flaggedRoutes, (value, key) => {

				//clone state
				const stateClone = { ...state };
				//for each flag key, find difference
				_.forIn(stateClone.flagged, (values, key) => {
					const filtered = _.differenceBy(_.get(stateClone, key), values, 'name')
					_.set(stateClone, key, filtered)
				})

				const flagItemsToAdd = value.filter(obj => (flags[obj.flag] && flags[obj.flag] == obj.flagValue) || (obj.roles && hasRoles(obj.roles)));
				const navigationItem = _.get(stateClone, key);
				const newState = [...flagItemsToAdd, ...navigationItem];
				const sorted = sortState(newState)
				_.set(state, key, sorted);
				delete flaggedRoutes[key];
			})

		});

		// check for stored default home on settings
		const mobileHome = rootGetters['settings/value']('mobileHome');
		const desktopHome = rootGetters['settings/value']('desktopHome');
		mobileHome && commit("setMobileHome", mobileHome);
		desktopHome && commit("setDesktopHome", desktopHome);

		// initialize resizer
		const phoneViewportDetector = _.debounce(() => {
			const hasPhoneViewport =
				Math.min(window.screen.width, window.screen.height) < 768 ||
				navigator.userAgent.indexOf("Mobi") > -1;
			commit('setPhoneViewport', hasPhoneViewport);
		}, 100);
		window.addEventListener("resize", phoneViewportDetector);
		phoneViewportDetector();
	},

	updateRoute({commit, state, getters}, route)
	{
		const visible = route.name && _.indexOf(state.excluded, route.name) === -1;
		const { meta, params, path } = route;
		commit('setRoute', {  meta, params, path});
		commit('setVisible', visible);

		//clear search if navigating to a page with search bar visible
		if(getters.isCollapsed && state.searchBarRoutes.indexOf(route.path) > -1)
		{
			store.dispatch('search/clear');
		}
	},
	loading({commit}, loading)
	{
		Object.entries(loading).forEach(([key, value]) =>
			commit("setLoading", { key, value })
		);
	},
	changeHome({ commit, dispatch, getters }, home) {
		if (getters.isCompact) {
			commit('setMobileHome', home);
			dispatch('settings/save', { name: 'mobileHome', value: home }, { root: true });
		} else {
			commit('setDesktopHome', home);
			dispatch('settings/save', { name: 'desktopHome', value: home }, { root: true });
		}
	},

	setOnline({ commit, dispatch }, online) {
		commit('setOnline', online);
		if (online) {
			dispatch('alerts/dismissAlert', null, { root: true });
		} else {
			dispatch('alerts/connection', 'offline', { root: true });
		}
	},
};

function sortState(newState) {
	let sorted = [];

	// first pass: add objects with no 'after' property to the sorted array
	for (let obj of newState) {
		if (!obj.after) {
			sorted.push(obj);
		}
	}

	// second pass: add objects with an 'after' property to the correct position
	for (let obj of newState) {
		if (obj.after) {
			let index = sorted.findIndex(o => o.name === obj.after);
			if (index !== -1) {
				sorted.splice(index + 1, 0, obj); // insert object after the 'after' object
			} else {
				sorted.push(obj); // 'after' object not found, add object to the end of the array
			}
		}
	}

	return sorted;
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};