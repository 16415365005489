import { Bridge } from '@k4connect/capacitor-plugin'
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { Deploy } from 'cordova-plugin-ionic';
import _ from 'lodash';
import { v5 as uuidv5 } from 'uuid';
import auth from '@/api/auth';
import env from '@/api/env';

import { set, get } from "@/utils/vuex";

const state = () => ({
	native: false,
	deviceInfo: {},
	uniqueIdentifier: null,
	inExternal: false,
	inVideo: false
});

const channels = {
	dev: 'Development',
	staging: 'Staging',
	production: 'Production'
}


const getters = {
	
	isK4App: get('native'),
	credentials: get('credentials'),
	isAndroid: (state) => {
		return state.deviceInfo.platform === 'android';
	},
	isIos: (state) => {
		return state.deviceInfo.platform === 'ios';
	},
	isWeb: (state) => {
		return state.deviceInfo.platform === 'web';
	},
	info: get('deviceInfo'),

	deviceId: (state, getters, rootState, rootGetters) => {
		rootGetters['settings/value']('deviceId');
	},

	name: (state) => {
		const { deviceInfo, uniqueIdentifier } = state;
		return `${ (deviceInfo.name) ? deviceInfo.name + '_' : '' }${deviceInfo.platform}_${uniqueIdentifier}`;
	},

};

const mutations = {
	setNative: set("native"),
	setDeviceInfo: set("deviceInfo"),
	setUniqueIdentifier: set("uniqueIdentifier"),
	setCredentials: set("credentials"),
	setInExternal: set("inExternal"),
	setInVideo: set("inVideo")
	
};

const actions = {
	init: async ({commit, rootGetters, dispatch}) => {
		
		

		const native = Capacitor.isNativePlatform();
		commit('setNative', native);

		const { uuid } = await Device.getId();
		commit('setUniqueIdentifier', uuid);

		const deviceInfo = await Device.getInfo();
		commit('setDeviceInfo', deviceInfo);


		const deviceId = rootGetters['settings/value']('deviceId');

		if(_.isEmpty(deviceId))
		{
			const id = await auth.getDevice();
			await dispatch('settings/save', { name: 'deviceId', value: id }, { root: true });
		}

		if(native)
		{

			const jwt = await auth.getCredentials();
			
			const sso = await env.sso();
			const environment = await env.name();

			//init bridge
			await Bridge.initialize({ jwt, sso, environment });	
			
			await Deploy.configure({
				channel: channels[environment],
				updateMethod: 'background'
			});
			
		}

	},

	checkForDeepLink: async({state}) => {

		if(!state.native)
		{
			return;
		}

		return new Promise((resolve) => {

			console.log('Checking for deeplink...');

			let attempts = 7;

			const timer = setInterval(async () => {
				
				try {
					
					const { url } = await Bridge.getDeeplink();
					
					if(url)
					{
						clearInterval(timer);

						const link = new URL(url);
						const action = link.searchParams.get('action');
						const redirect = link.searchParams.get('redirect');
						
						if(action === 'migration' && redirect)
						{
							console.log('Migrate deeplink found...', redirect);
							window.location.href = env.login();
						}

						resolve();
					}
					

				} catch(e) {
					//no link, invalid link
				}

				attempts--;

				if(attempts <= 0)
				{
					clearInterval(timer);
					resolve();
				}
				
			}, 1000);

		});
		
	},

	saveCredentials: async ({commit, state}, credentials) => {
		
		if(state.detected)
		{
			await Bridge.saveKeychain(credentials);
			commit('setCredentials', credentials);
		}
		
	},

	call: async ({getters, commit}, payload) => {

		if(getters.isK4App)
		{
			
			await Bridge.showVideo({payload});	
			commit('setInVideo', true);
		}
	},

	

	open: async ({getters, commit}, url) => {
		commit('setInExternal', true);
		if(getters.isK4App)
		{
			await Bridge.showExternal({
				url
			});
		} else {
			window.open(url, '_blank');
		}
	},

	resume: async ({commit, state}) => {

		//clear inExternal or inVideo, and return true if neither were true before resume
		const fromBackground = !state.inExternal && !state.inVideo;

		console.log('fromBackground: ' + fromBackground + ' inExternal: ' + state.inExternal + ' inVideo: ' + state.inVideo);

		commit('setInExternal', false);
		commit('setInVideo', false);

		return fromBackground;

	},

	updateDeviceNameForWeb: async({commit}, userId) => {
		const deviceInfo = await Device.getInfo();

		if(deviceInfo.platform === 'web') {
			const uuid = uuidv5('web', userId);
			commit('setUniqueIdentifier', uuid);
		}
	},

	clearMobileJWT: async({state}) => {

		const { native }= state;

		if(native) {
			const sso = await env.sso();
			const environment = await env.name();
			const jwt = '';
			await Bridge.initialize({ jwt, sso, environment });
		}
		
	},

	openDeeplink: async(ctx, link) => {
		await Bridge.openDeeplink({ link });
	},

	clearDeeplink: async() => {
		await Bridge.clearDeeplink({});
	},
	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};