export default {
	install(Vue)
	{
		Vue.prototype.$strings = strings;
	}
}

const strings = {
	errors: {
		generic: 'Something went wrong! Please try again.',
		chat: {
			send: 'Unable to send message! Please try again',
			channel: 'Unable to create channel! Please try again later.',
			login: 'Chat login failed! Please try again later.',
			staff: 'Unable to find Community Staff user.'
		},
		events: {
			signup: 'Unable to rsvp for event! Please try again later.',
			withdraw: 'Unable to withdraw from event! Please try again later.',
			accept: 'Unable to accept invite! Please try again later.',
			decline: 'Unable to decline invite! Please try again later.',
			comments: 'Unable to save comments! Please try again later.'
		},
		share: 'Unable to send invite at this time.',
		form: 'Unable to submit form at this time.',
		profile: 'Unable to update profile at this time.',
		search: 'Unable to search at this time.',
		internet: {
			offline: 'You are currently offline. Please check your internet connection and try again.',
			maybeOffline: 'You may be currently offline. Please check your internet connection and try again.',
			slow: 'Please check your internet, you are currently experiencing a poor connection that may hinder app performance.',
			offlineCall: 'The call could not be made. Make sure your phone is connected to the internet and try again.'
		}
	},
	notRegisteredK4User:
		'No registered account was found with the credentials. Please try registering with the email first.',
	alreadyLinked:
		'The Login credentials were used with a different account. Please try with different email or account.',
	notFound: 'Oops! Page not found',
	backButton: 'Back',
	validation: {
		email: {
			required: 'The email is required.',
			valid: 'Please enter a valid email address.',
			wrong: 'Please try a different email.',
		},
		password: {
			required: 'Password is required.',
		},
		confirm: {
			required: 'Confirm password is required.',
		},
		firstName: {
			required: 'The first name is required',
			valid: 'Please enter a valid first name',
		},
		lastName: {
			required: 'The last name is required',
			valid: 'Please enter a valid last name',
		},
		relationship: {
			required: 'Please selact a relationship',
		},
		phone: {
			valid: 'Please enter a valid phone number'
		},
		code: {
			required: 'Please enter a code.',
			valid: `Please make sure you've entered a valid code.`,
		},
		guest: {
			required: 'Please enter guests name.',
		},
		generic: {
			required: 'This information is required.'
		}
	},
	label: {
		email: 'Email',
		password: 'Password',
		confirm: 'Confirm',
		firstName: 'First Name',
		lastName: 'Last Name',
		phone: 'Phone',
	},
	login: {
		linkButton: 'Have a code?',
		linkStaff: 'Staff login',
		submit: 'Get started',
		password: {
			linkButton: 'Forgot password?',
			submit: 'Log In',
			goBack: 'Go Back',
			forgot: (email) =>
				`We’ve sent an email to ${email} with a link to help verify your identity.`,
			error: {
				'auth/too-many-requests':
					'You’ve reached the maximum number of failed login attempts.\nPlease press “Forgot Password” to reset your login credentials.',
				'auth/wrong-password': 'The password is incorrect. Please try again',
			},
		},
		differentProvider:
			'Looks like you might have logged in using a different provider previously. Please try using one of the other options.',
		mailNotExist: (email) =>
			`We could not find ${email}.\nPlease make sure you have entered a valid email address.`,
		code: {
			description: (email) =>
				`We’ve sent an email to ${email} with a 6-digit code to help us verify your identity. Please enter it below.`,
			linkButton: 'Log in instead',
			submit: 'Next',
		},
		staff: {
			error: `Your username and/or password were incorrect.\nPlease try again or click "Forgot Password" to reset your password.`,
			goBack: 'Go Back',
			linkButton: 'Forgot password?',
			submit: 'Log In',
		},
	},
	loginFooter: {
		help: 'Need help? Email ',
		supportEmail: 'support@K4Connect.com',
		call: ' or call us at ',
		phone: '855-876-9673',
		privacy: 'Privacy Policy',
		url: 'https://www.k4connect.com/privacy',
	},
	enterCode: {
		description:
			'If you have a 6-digit code, please enter it below. If you are unsure or need help generating one, please contact community staff for further assistance.',
		linkBack: 'Have an email?',
		submit: 'Get started',
		codeLabel: '6-digit code',
		invalidToken: 'The code entered is not valid. Please request a new code.',
	},
	newPassword: {
		description:
			'Please create a new password that you can use to log in with next time.',
		linkButton: 'Back to Login',
		submit: 'Submit',
		notMatch: 'Please enter the same password and confirmation.',
		error: {
			'auth/invalid-action-code': 'Invalid password reset code. Please request a new one.'
		}
	},
	createUser: {
		description: 'Sign up using a personal email.',
		submit: 'Submit',
	},
	federated: {
		signup: 'Or signup with any of the following accounts.',
		choose: 'Signed up before with one of these accounts? Choose an option below.',
		error: {
			'auth/account-exists-with-different-credential' : 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
		}
	},
	whatsNew: {
		caughtUp: {
			title: 'Congratulations!',
			paragraph: 'You’re all caught up now.',
		},
		older: 'Show older posts',
	},
	events: {
		filters: {
			title: 'Filters',
			close: 'Done',
			clear: 'Clear',
			time: 'Time',
			price: 'Price',
			calendars: 'Calendars'
		},
		external: 'This is an external signup link and will open to another page. Signups will not appear under My Events.',
		findMore: 'Load More',
		allDay: 'All day',
		today: 'Today',
		tomorrow: 'Tomorrow',
		costsMoney: '$ Costs Money',
		unlimitedSpots: 'Unlimited Spots',
		spotsLeft: 'spots left',
		spotLeft: 'spot left',
		eventFull: 'This event is full',
		attend: 'Attend',
		withdraw: 'Withdraw',
		add: 'Add to My Calendar',
		waitlist: 'Join Waitlist',
		waitlisted: 'Waitlisted',
		waitlistSignUp:
			'Success! A staff member will contact you when a spot becomes available',
		waitlistWithdraw: "You've been removed from the waiting list.",
		attending: 'Attending',
		backButton: 'Back',
		myEvents: 'My Events',
		signup: 'We look forward to seeing you at this event!',
		full: "We're sorry, but this event is full.",
		fullWithGuest:
			"We're sorry, but there is not two available slots for this event.",
		empty: 'There are no events that meet your criteria.',
		emptyEvents: 'Events that you choose to attend \n can be found here.',
		emptySignups: 'No signups found for this event',
		emptyInvites: 'Event invites can be found here.',
		viewDetails: 'View Event Details',
		addComments: 'Add Comments to Signup',
		withdrawn: "You've successfully withdrawn from this event",
		guestName: 'Guest Name',
		inputLabel: 'Enter a guest name to add:',
		me: 'Me',
		guestLabel: 'Outside Guest',
		guest: 'Me and a Guest',
		cancel: 'Cancel',
		submit: 'Submit',
		comments: 'Comments / special requests:',
		commentsSave: 'Comment updated.',
		onlyLetters: 'Name must only contain letters.',
		minimum: 'Name must have at least 3 characters.',
		search: {
			label: 'Guest name',
		},
		caughtUp: {
			body: 'That’s all the new events since you last checked K4Community Plus.',
		},
		informative: {
			oneSpot:
				"There's only one spot available. You will only be able to sign up yourself.",
			withdraw:
				'You may only withdraw yourself from this event. If you signed up a guest, your guest must contact the staff and ask to be withdrawn from the event.',
		},
		signMeUp: 'Sign Me Up',
		withdrawMyself: 'Withdraw Myself',
		notAttended:
			'You have successfully chosen to attend this event. This is a demo for Staff users, the event will not be affected.',
		notWaitlisted:
			'You have successfully added yourself to the waitlist. This is a demo for Staff users, the event will not be affected.',
		notAccepted:
			'You have successfully chosen to accept this invite. This is a demo for Staff users, the event will not be affected.',
		notDeclined:
			'You have successfully chosen to decline this invite. This is a demo for Staff users, the event will not be affected.',
		
	},
	feed: {
		readMore: 'Read more',
		readLess: 'Read Less',
		menu: 'Menu',
		viewPDF: 'View PDF',
		open: 'Open',
		category: {
			all: 'All',
			Notices: 'Notices',
			Menu: 'Dining',
			Resource: 'Resources',
			Photo: 'Photos',
			Video: 'Videos',
		},
		filter: {
			title: 'Filters',
			close: 'Done',
			all: 'All',
			subtitle: {
				Notice: 'Notices',
				Menu: 'Restaurants',
				Resource: 'Resources',
				Photo: 'Photos',
				Video: 'Videos',
			},
		},
		caughtUp: {
			title: 'You’re all caught up!',
			body: 'That’s all the new posts since you last checked K4Community Plus.',
		},
	},
	logout: {
		description:
			'This will log you out of K4Community Plus. You will be returned to the login screen where you may choose to log in again.',
		cancel: 'Cancel',
		close: 'Log Out',
		title: 'Log Out'
	},
	invalidEnv: 'CHECK ENV',
	search: {
		empty: 'No results found.',
		getStarted: 'Enter your search above to get started...',
		getStartedLeft: 'Enter your search in the sidebar to get started...',
		label: 'Search',
		length: (chars) => `Search must have at least ${chars} characters.`,
	},
	directory: {
		tellUs: "Tell us about you!",
		createBio: 'Create Bio',
		empty: 'There are no contacts that meet your criteria.',
		emptyList: 'Friends and family can be found here.',
		search: {
			privateEmailDomain: '@k4connect.private',
		},
		filter: {
			title: 'Filters',
			close: 'Done',
			subtitle: {
				group: 'Group',
			},
			options: {
				all: 'All',
				residents: 'Residents',
				family: 'Friends & Family',
			},
		},
		profile: {
			route: '/directory/profile',
			pendoEvents: {
				arrival: 'profile_open',
				cancelDialog: 'profile_cancel',
				cancelDialogDiscard: 'profile_cancel_confirm',
				cancelDialogClose: 'profile_cancel_cancel',
				save: 'profile_done',
			},
			form: {
				contactCommunity:
					'Please contact community staff to change your address or email.',
				save: 'Save',
				cancel: 'Cancel',
				discard: 'Discard changes',
				primaryPhone: 'Phone',
				birthday: 'Birthday',
				email: 'Email',
				address: 'Address',
				building: 'Building',
				biography: 'Biography',
				unsavedChanges:
					'It looks like you have some unsaved changes. If you leave now, your changes will be discarded. You may cancel to go back and save your changes.',
				profileVisibility: 'Hide my profile',
				profileVisibilityEmail: 'Hide my email',
				profileVisibilityPrimaryPhone: 'Hide my primary phone',
				profileVisibilitySecondaryPhone: 'Hide my secondary phone',
				profileVisibilityBirthday: 'Hide my birthday',
				asterisk: '* These fields are not set on your profile.',
			},
			empty: 'Page only available for residents',
			homeScreen: 'Change your home screen.',
			themeOverride: 'Select a theme.',
			communitySelector: 'Select a Community',
			confirmCommunitySelect: {
				text: 'Are you sure you want to change your community?',
				cancel: 'Cancel',
				confirm: 'Confirm'
			},
			toggleDark: 'Toggle light and dark mode.',
			public:
				'Your profile is visible to everyone and they will be able to contact you in K4Community Plus.',
			hidden:
				'Your profile is only visible to you. No one will be able to contact you in K4Community Plus.',
			unableToCommunicate:
				'You are unable to communicate with this person through K4Community Plus because your profile is hidden. ',
		},
		detail: {
			edit: 'Edit',
			done: 'Done',
			address: 'Address',
			building: 'Building',
			email: 'Email',
			primaryPhone: 'Primary Phone',
			secondaryPhone: 'Secondary Phone',
			birthdate: 'Birthday',
			biography: 'Biography',
			chat: 'Chat',
			close: 'Close',
			jobTitle: 'Title',
			department: 'Department',
		},
		alexa: {
			activateDescription:
				'You may use this number to make and receive calls with your loved ones from an Alexa enabled device',
			activateLabel: 'Enable Alexa calling',
			limitMessage:
				'You have reached the maximum number of friends and family allowed in their Alexa directory.',
			secondLimitMessage:
				'Disable Alexa calling for an existing friend or family member to enable Alexa calling for this person.',
		},
		list: {
			route: '/directory',
		},
	},
	share: {
		text: 'Share K4Community Plus with friends and family.',
		description: 'Send an invitation to a Friend or Family member so they can use K4Community Plus to receive timely notices, important information, and more from your community.',
		first: `Friend's First Name`,
		last: `Friend's Last Name`,
		email: `Friend's Email Address`,
		phone: `Friend's Phone Number (Optional)`,
		relationship: `Relationship`,
		button: {
			cancel: 'CANCEL',
			send: 'SEND',
		},
		toast: {
			success: 'Great! Your invitation is on its way.',
			noInvites:
				'You have successfully shared an invite for K4Community Plus. \n This is a demo. No invites will actually be sent.',
		},
	},
	noResults: {
		alt: 'No results found',
	},
	components: {
		profilePhoto: {
			editButtonText: 'Edit Photo',
		},
		darkMode:
		{
			label: 'Dark Mode'
		}
	},
	chat: {
		messageInput: {
			button: 'Send',
		},
		close: 'Close',
		more: 'Load Older',
		empty: 'Try sending a new message.',
		copySuccess: 'Copied to clipboard',
		copyFail: 'Copy failed',
		channelList: {
			title: 'Chat',
			subtitle: 'Recent',
			button: 'New',
			empty: 'There are no conversations to display.',
			noMessages: 'Nothing yet...',
			you: 'You'
		},
		previewChannel: {
			delete: 'Delete',
		},
		deleteAll: 'Delete All',
		deleteDialog: {
			text: ' This will delete the entire conversation from all of your devices.',
			cancel: 'Cancel',
			delete: 'Delete',
		},
		search: {
			label: 'Type the name of a person',
			noResult: 'There are no contacts that meet your search criteria.',
		},
		fileUpload: {
			maximumSize: 'Please make sure each file is less than 20MB.',
			maxCount: 'You can only upload 10 attachments at a time'
		},
		videoAtatchment: 'Sharing video file is not currently supported',
		continue: 'OK',
	},
	restaurant: {
		empty: 'It looks like the community team has not entered this information.',
		readMore: "Read More",
		readLess: "Read Less",
		nutritionInfo: "Nutritional Info:",
		alergyInfo: "Allergy Info:",
		ingredientsInfo: "Ingredients:",
	},
	controls: {
		thermostat: {
			heat: 'Heat Mode',
			cool: 'Cool Mode',
			auto: 'Auto Mode',
			magic: 'Magic Mode',
			off: 'Off'
		},
		scenes: {
			loading: 'Loading Scenes...',
			empty: "No scenes available. Tap the '+' button to add one.",
			addSuccess: 'Scene added successfully.',
			addError: 'Error adding scene. Please try again.',
			deleteSuccess: 'Scene deleted successfully.',
			deleteError: 'Error deleting scene. Please try again.',
			editSuccess: 'Scene edited successfully.',
			editError: 'Error editing scene. Please try again.',
		},
		info: {
			connecting: 'Connecting to the Controller...',
			connected: 'Loading controls...',
			error: "Couldn't connect to the controller.\nIf this persists please contact your staff for help."
		}
	},
	form: {
		open: 'Fill out',
		cancel: 'Cancel',
		submit: 'Submit',
		success: 'You form has been submitted. Expect a followup soon!'
	},
	profile: {
		edit: 'Edit',
		cancel: 'Cancel',
		save: 'Save',
		description: 'Please contact community staff to change your address or email.',
		biography: 'Update your biography...',
		success: 'Your biography was updated.'
	},
	calls: {
		incomingDialog: {
			text: 'Incoming Call...',
			confirm: 'Accept',
			cancel: 'Cancel'
		},
		left: 'The other caller left the call.',
		declined: 'The other caller declined the call.',
		cancelled: 'The other caller cancelled the call.'
	},
	shortcuts: {
		empty: 'It looks like the community team has not entered this information.',
	},
}

export { strings }