import Vue from 'vue'
import moment from 'moment';
import App from './App.vue'
import bugsnag from './plugins/bugsnag';
import Bugsnag from '@bugsnag/js'
import pendo from './plugins/pendo';
import LaunchDarklyPlugin from "./plugins/launchdarkly";
import vuetify from './plugins/vuetify';
import strings from './plugins/strings';
import cypress from './plugins/cypress';
import rules from './plugins/rules';
import store from './store'
import router from './router'
import env from '@/api/env';
import papi from '@/api/papi';
import sceneWizards from './plugins/sceneWizards';
import firebase from './api/firebase';


Vue.config.productionTip = false

Vue.use(strings);
Vue.use(rules);
Vue.use(sceneWizards);

Vue.use(bugsnag);

if(env.name() !== 'production')
{
	//add helper plugin to populate data-cy attributes
	Vue.use(cypress);
}

let launched = false;

async function main()
{
	window.vm = new Vue({
		vuetify,
		store,
		router,
		render: (h) => h(App),
	}).$mount("#app");

	try {
		//load settings for light/dark
		const [, jwt] = await Promise.all([
			store.dispatch('settings/init'),
			papi.loadJwt()
		]);

		if(!jwt)
		{
			//redirect will happen, just exit
			return;
		}

		// setup graphql client
		papi.setup();
	} catch(e) {
		//redirect will happen, just exit
		return;
	}

	try {
		//load stores
		await Promise.all([
			store.dispatch("initialize"),
			store.dispatch("user/preload"),
		]);
		store.dispatch("user/load"); // do not wait for home controls
	} catch (e) {
		Bugsnag.notify(e);
		console.error("Failed to initialize!", e);
		return;
	}

	try {
		//init Launch Darkly with user
		const user = store.getters["user/current"];
		Vue.use(LaunchDarklyPlugin, {
			ldOptions: {
				streaming: false,
			},
			user: {
				name: user.fullName,
				key: user._id,
				custom: {
					email: user.email,
					roles: user.roles,
					fullname: user.fullName,
					alexaAddressBook: user.alexaAddressBook,
					communityId: user.community.communityId,
					isResident: store.getters["user/isResident"],
					isFamily: store.getters["user/isFamily"],
					isStaff: store.getters["user/isStaff"],
					isAdvocate: store.getters["user/isAdvocate"],
				},
			},
		});
	} catch (e) {
		Bugsnag.notify(e);
		console.error("Launch darkly load failed!", e);
	}

	try {
		await Promise.all([
			store.dispatch("flags/load"),
			store.dispatch("notifications/load"),
			store.dispatch("chat/load"),
			store.dispatch("badges/load"),
		]);
	} catch (e) {
		console.error("Notifications & chat load failed!", e);
	}

	try {
		const user = store.getters["user/current"];
		const communityName = store.getters["user/communityName"];
		const deviceInfo = store.getters["mobile/info"];
		const dark = store.getters["theme/dark"];
		const theme =
			store.getters["settings/value"]("theme") || store.state.theme.current;
		await pendo.initialize(user, communityName, deviceInfo, dark, theme);
	} catch (e) {
		Bugsnag.notify(e);
		console.error("Pendo load failed!", e);
	}

	try {
		const user = store.getters["user/current"];
		const communityName = store.getters["user/communityName"];
		const deviceInfo = store.getters["mobile/info"];
		const dark = store.getters["theme/dark"];
		const theme =
			store.getters["settings/value"]("theme") || store.state.theme.current;
		await firebase.setAnalytics(user, communityName, deviceInfo, dark, theme);
	} catch (e) {
		Bugsnag.notify(e);
		console.error("Firebase load failed!", e);
	}

	window.addEventListener("error", (error) => {
		const msg = error?.message?.toLowerCase();

		// ignore ResizeObserver errors
		if (msg && msg.includes("resizeobserver")) {
			return true;
		}

		console.error(error);
		throw error;
	});


	router.initialize();
	launched = true;

}

main();
setTimeout(async ()=>{
	if(!launched)
	{
		console.error('Failed to launch app!')
		Bugsnag.notify('Failed to launch app!', null, () => {
			window.location.reload();
		});
	}
	
}, 20000);




Vue.filter('formatTime', function(value) {
	if (value) {
		return moment(new Date(value)).format('h:mm A')
	}
});

Vue.filter('formatAgo', function(value) {
	if (value) {
		const now = moment();
		const days = now.diff(new Date(value), "days");

		return (days < 0) ? 'Upcoming' : (days === 0) ? 'Today' : (days === 1) ? `${days} day ago` : `${days} days ago`
	}
});

Vue.filter('formatDate', function(value) {
	if (value) {

		const date = moment(value).startOf('day');
		const now = moment().startOf('day');
		const days = date.diff(now, "days");

		if(days < -1 || days > 1)
		{
			return date.format('MMMM Do YYYY');
		} else if(days === -1)
		{
			return 'Yesterday';
		} else if(days === 1) {
			return 'Tomorrow';
		} else {
			return 'Today'
		}

	}
});

Vue.filter('formatBirthday', function(value) {
	if (value) {
		const date = moment(value);
		return date.format('MMM D');
	}
});


Vue.filter('formatPhone', function (phone) {
  
	//remove country code if present '+1 area' or '1-area'
	if(phone[0] === '+') 
	{
		let parts = phone.split(' ');
		parts.shift()
		phone = parts.join(' ');
		
	} else if(phone.indexOf('-') > -1) {
		let parts = phone.split('-');
		if(parts.length == 4) {
			parts.shift()
			phone = parts.join('-')
		}
	}
	let formattedPhone = phone.replace(/[^0-9]/g, '').substring(0, 10)
					.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
	let extension = phone.replace(/[^0-9]/g, '').substring(10);

	if(extension && extension.length > 0)
	{
		formattedPhone += ` Ext. ${extension}`;
	}

	return formattedPhone;  
});

